import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CS } from "../Typography";

type BadgeProps = {
  background: string;
  category: string;
  colour: string;
};

const RecommendationBadge: FunctionComponent<BadgeProps> = (props) => {
  return <S.Badge {...props}>{props.category}</S.Badge>;
};

const S = () => {};

S.Badge = styled(CS)<BadgeProps>`
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 0;
  background: ${(p) => p.background};
  padding: 6px 8px;
  color: ${(p) => p.colour};
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 0 0 5px 5px;
`;

export default RecommendationBadge;
